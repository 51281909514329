import {EditorSDK} from '@wix/platform-editor-sdk';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {clickToPreviewWishlistInMembersSfe} from '@wix/bi-logger-ec-sf/v2';

import {DependantApps} from '../../services/DependantApps';

export const showInstallNotification = async ({
  sdk,
  t,
  dependantApps,
  installMembersArea,
  webBiLogger,
}: {
  sdk: EditorSDK;
  t: (keyToTranslate: string) => string;
  dependantApps: DependantApps;
  installMembersArea: boolean;
  webBiLogger: WebBiLogger;
}) => {
  const linkClicked = await sdk.editor.showUserActionNotification('', {
    message: installMembersArea
      ? t('settings.productPage.floatingNotification.membersAreaAdded')
      : t('settings.productPage.floatingNotification.wishlistAdded'),
    type: sdk.editor.NotificationType.Success,
    link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
  });

  if (linkClicked) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(clickToPreviewWishlistInMembersSfe({}));
    await dependantApps.navigateToWishlist();
  }
};
